import React, { Component } from 'react';
import NfiWhitepaper from '../images/nfi_whitepaper_final.pdf';

export default class TermsOfUse extends Component {
  render() {
    return (
      <>
        <iframe style={{ width: '100%', height: '100%' }} src={NfiWhitepaper} />
      </>
    );
  }
}
